/** @jsx jsx */
import React from 'react'
import { css, jsx, ThemeProvider } from '@emotion/react'
import Layout from '../components/Layout'
import { Column } from '@swp/components/lib/Column'
import { ColumnWrap } from '@swp/components/lib/Column'
import { ColumnWrapper } from '@swp/components/lib/Column'
import { Text } from '@swp/components/lib/Text'
import { Menu } from '@swp/components/lib/Menu'
import { Image } from '@swp/components/lib/Image'
import { MenuWrap } from '@swp/components/lib/Menu'
import { MenuButton } from '@swp/components/lib/Menu'
import { MenuWrapBox } from '@swp/components/lib/Menu'
import { MenuButtonClose } from '@swp/components/lib/Menu'
import { Button } from '@swp/components/lib/Button'
import { Title } from '@swp/components/lib/Title'

const theme = {
  "id": 127,
  "colors": {
    "front1": "rgba(232,232,232,1)",
    "front2": "rgba(255,255,255,1)",
    "front3": "rgba(0,0,0,1)",
    "front4": "rgba(0,0,0,1)",
    "back1": "rgba(255,255,255,1)",
    "back2": "rgba(0,0,0,1)",
    "back3": "rgba(236,236,236,1)",
    "back4": "rgba(178,178,178,1)"
  },
  "variables": {
    "color-variable-1": "rgba(251,201,0,1)",
    "color-variable-2": "rgba(242,243,245,1)",
    "color-variable-btn-1": "rgba(255,255,255,1)",
    "color-variable-btn-2": "rgba(0,0,0,1)"
  },
  "globalMobile": "27em",
  "meta": []
}

export default function Page(props) {
  return (
    <ThemeProvider theme={theme}>
      <Layout title={"homepage"}>
        <Column style={{"backgroundColor":"rgba(0,0,0,1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 --full pl--10 pr--10" columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="mb--0 mt--0">
              
              <Text className="text-box text-box--left fs--14 w--600 text-box--invert" content={"Jsme Vám k dispozici od <span style=\"color: rgb(251, 201, 0);\">8:00</span> do <span style=\"color: rgb(251, 201, 0);\">16:00</span>, PO—PÁ"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--0 mt--0">
              
              <Text className="text-box text-box--right fs--14 w--600 text-box--invert" content={"info@autobezproblemu.cz&nbsp; <span style=\"color: rgb(251, 201, 0);\">/</span>   +420 555 333 961 "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--02 pt--02" menu={true} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <Menu className="--full pb--06 pt--06" fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box --left" style={{"maxWidth":""}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/2058/328ed3f63e934c1eabe5e72de6202b43.svg"} svg={false} href={"https://autobezproblemu.cz"} sizes={"100vw"} style={{"maxWidth":170}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 btn-box--invert pl--06 pr--06" href={"https://autobezproblemu.cz/#vozy"} target={""} content={"Nyní dostupné vozy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 btn-box--invert pl--06 pr--06" href={"https://autobezproblemu.cz/#o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 btn-box--invert pl--06 pr--06" href={"https://autobezproblemu.cz/#jak-to-funguje"} target={""} content={"Jak to funguje"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn1 btn-box--shape2 fs--18 w--600 pl--08 pr--08" innerClassName="pt--12 pb--12 pl--20 pr--20" href={"/poptat"} pbtn={null} target={""} content={"Poptat kalkulaci"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="bg--center pb--60 pt--60" name={"0nibe74fiqhm"} style={{"backgroundColor":"rgba(242, 243, 245, 1)"}} border={null} parallax={false} css={css`
          @media (min-width: 1401px) {
            background-image: url(https://cdn.swbpg.com/t/11212/fd439a4c23394c50adf76052b304fa44_s=2000x_.jpg);
          }
          @media (max-width: 1400px) {
            background-image: url(https://cdn.swbpg.com/t/11212/fd439a4c23394c50adf76052b304fa44_s=1400x_.jpg);
          }
        `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--left pb--20 pt--08">
              
              <Title className="title-box fs--86 w--600" content={"Nové auto bez<br>zbytečných překážek"}>
              </Title>

              <Text className="text-box text-box--left fs--22 mt--25" content={"<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;✔&nbsp;</span>&nbsp; &nbsp;Včetně <span style=\"font-weight: bold;\">pojištění</span> a <span style=\"font-weight: bold;\">servisu</span> po dobu financování"}>
              </Text>

              <Text className="text-box text-box--left fs--22" content={"<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;✔&nbsp;</span>&nbsp; &nbsp;Bez&nbsp;<span style=\"font-weight: bold;\">nahlížení do registru a pro všechny</span>"}>
              </Text>

              <Text className="text-box text-box--left fs--22" content={"<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;✔&nbsp;</span>&nbsp; &nbsp;Auto dostanete již&nbsp;<span style=\"font-weight: bold;\">do několika mibnut</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--40 pt--40" name={"tw4xide7esh"} style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l1"} parallax={true}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":1530}} columns={"3"}>
            
            <ColumnWrapper className="--left pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"rgba(0,0,0,1)"}} border={null}>
              
              <Title className="title-box title-box--invert" content={"Vyberte si z vozidel skladem."}>
              </Title>

              <Text className="text-box text-box--invert mt--10" content={"Dodání ihned."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2" href={"#vozy"} content={"Prohlížet vozy"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"rgba(242,243,245,1)"}} border={null}>
              
              <Title className="title-box" content={"Nechte si<br>vytvořit nabídku.<br>"}>
              </Title>

              <Text className="text-box mt--10" content={"Financování na míru."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2" href={"/poptat"} content={"Poptat nabídku"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null}>
              
              <Title className="title-box" content={"Máte dotaz?<br>Kontaktujte nás.<br>"}>
              </Title>

              <Text className="text-box mt--10" content={"Rádi Vám poradíme."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--cColor2" href={"mailto:info@autobezproblemu.cz"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--10" name={"vozy"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1530}} columns={"1"}>
            
            <ColumnWrapper className="--left pb--25 pl--30 pr--30 pt--25">
              
              <Title className="title-box fs--62" content={"Nyní dostupné vozy<br>"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":799}} content={"Auta u nás nabízíme ve dvou režimech financování. V režimu FULL hradíme veškeré náklady na opravy, servis, pneumatiky a pod. Nestaráte se vůbec o nic.\n\nV režimu LITE hradíme běžné servisní prohlídky auta. Poruchy, díly mimo běžný servis a pneumatiky si hradíte sami. "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mt--50" columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image className="--left" alt={""} src={"https://cdn.swbpg.com/t/2058/a4dc9921ef224c7abbc6206b55837942_s=860x_.jpeg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":400}} srcSet={"https://cdn.swbpg.com/t/2058/a4dc9921ef224c7abbc6206b55837942_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2058/a4dc9921ef224c7abbc6206b55837942_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2058/a4dc9921ef224c7abbc6206b55837942_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/2058/a4dc9921ef224c7abbc6206b55837942_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/2058/a4dc9921ef224c7abbc6206b55837942_s=2000x_.jpeg 2000w"} content={null} position={null}>
              </Image>

              <Title className="title-box mb--0 mt--30 pl--0 pr--0" content={"Škoda Octavia II"}>
              </Title>

              <Text className="text-box mt--02 pl--0 pr--0" content={" Facelift 1.6 TDI Combi"}>
              </Text>

              <Text className="text-box mt--10 pl--0 pr--0" content={"Režim LITE od<span style=\"font-weight: bold;\"> 5 800 Kč </span>/ měsíc"}>
              </Text>

              <Text className="text-box mt--0 pl--0 pr--0" content={"Režim&nbsp;<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;<span style=\"font-weight: bold;\">FULL</span>&nbsp;</span> od <span style=\"font-weight: bold;\">6 500 Kč</span> / měsíc"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 mt--30 pl--0 pr--0" href={"/skoda-octavia-ii-facelift-16tdi-combi"} content={"Zobrazit detail&nbsp; &nbsp;<span style=\"font-weight: normal;\">➔</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image className="--left" alt={""} src={"https://cdn.swbpg.com/t/2058/2d34f76b493f475297f91df4ef9931b2_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":400}} srcSet={"https://cdn.swbpg.com/t/2058/2d34f76b493f475297f91df4ef9931b2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2058/2d34f76b493f475297f91df4ef9931b2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2058/2d34f76b493f475297f91df4ef9931b2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2058/2d34f76b493f475297f91df4ef9931b2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2058/2d34f76b493f475297f91df4ef9931b2_s=2000x_.jpg 2000w"} content={null} position={null}>
              </Image>

              <Title className="title-box mb--0 mt--30 pl--0 pr--0" content={"Škoda Octavia III"}>
              </Title>

              <Text className="text-box mt--02 pl--0 pr--0" content={"1.6 TDI Combi"}>
              </Text>

              <Text className="text-box mt--10 pl--0 pr--0" content={"Režim LITE od<span style=\"font-weight: bold;\"> 7 000 Kč </span>/ měsíc"}>
              </Text>

              <Text className="text-box mt--0 pl--0 pr--0" content={"Režim&nbsp;<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;<span style=\"font-weight: bold;\">FULL</span>&nbsp;</span> od <span style=\"font-weight: bold;\">8 800 Kč</span> / měsíc"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 mt--30 pl--0 pr--0" href={"/skoda-octavia-iii-16tdi-combi"} content={"Zobrazit detail&nbsp; &nbsp;<span style=\"font-weight: normal;\">➔</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image className="--left" alt={""} src={"https://cdn.swbpg.com/t/2058/4236e7e6784e40329fdc452f9fc16e27_s=860x_.jpeg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":400}} srcSet={"https://cdn.swbpg.com/t/2058/4236e7e6784e40329fdc452f9fc16e27_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2058/4236e7e6784e40329fdc452f9fc16e27_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2058/4236e7e6784e40329fdc452f9fc16e27_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/2058/4236e7e6784e40329fdc452f9fc16e27_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/2058/4236e7e6784e40329fdc452f9fc16e27_s=2000x_.jpeg 2000w"} content={null} position={null}>
              </Image>

              <Title className="title-box mb--0 mt--30 pl--0 pr--0" content={"Octavia III TDI DSG aut."}>
              </Title>

              <Text className="text-box mt--02 pl--0 pr--0" content={"1.6 TDI Combi"}>
              </Text>

              <Text className="text-box mt--10 pl--0 pr--0" content={"Režim LITE od<span style=\"font-weight: bold;\">&nbsp;7 000 Kč </span>/ měsíc"}>
              </Text>

              <Text className="text-box mt--0 pl--0 pr--0" content={"Režim&nbsp;<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;<span style=\"font-weight: bold;\">FULL</span>&nbsp;</span> od <span style=\"font-weight: bold;\">8 900 Kč</span> / měsíc"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 mt--30 pl--0 pr--0" href={"/skoda-octavia-iii-tdi-dsg-automovat"} content={"Zobrazit detail&nbsp; &nbsp;<span style=\"font-weight: normal;\">➔</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--40" style={{"maxWidth":""}}>
              
              <Image className="--left" alt={""} src={"https://cdn.swbpg.com/t/2058/602791f63ca24569ae6df0f986c2ddca_s=860x_.jpeg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":400}} srcSet={"https://cdn.swbpg.com/t/2058/602791f63ca24569ae6df0f986c2ddca_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2058/602791f63ca24569ae6df0f986c2ddca_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2058/602791f63ca24569ae6df0f986c2ddca_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/2058/602791f63ca24569ae6df0f986c2ddca_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/2058/602791f63ca24569ae6df0f986c2ddca_s=2000x_.jpeg 2000w"} content={null} position={null}>
              </Image>

              <Title className="title-box mb--0 mt--30 pl--0 pr--0" content={"Škoda Superb III"}>
              </Title>

              <Text className="text-box mt--02 pl--0 pr--0" content={"TDI Limuzína"}>
              </Text>

              <Text className="text-box mt--10 pl--0 pr--0" content={"Režim LITE od<span style=\"font-weight: bold;\"> 12 900 Kč </span>/ měsíc"}>
              </Text>

              <Text className="text-box mt--0 pl--0 pr--0" content={"Režim&nbsp;<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;<span style=\"font-weight: bold;\">FULL</span>&nbsp;</span> od <span style=\"font-weight: bold;\">13 900 Kč</span> / měsíc"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 mt--30 pl--0 pr--0" href={"/skoda-superb-iii-tdi-limuzina"} content={"Zobrazit detail&nbsp; &nbsp;<span style=\"font-weight: normal;\">➔</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--40" style={{"maxWidth":""}}>
              
              <Image className="--left" alt={""} src={"https://cdn.swbpg.com/t/2058/9c12916c71be4aa5908b6b5ca5fefb23_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":400}} srcSet={"https://cdn.swbpg.com/t/2058/9c12916c71be4aa5908b6b5ca5fefb23_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2058/9c12916c71be4aa5908b6b5ca5fefb23_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2058/9c12916c71be4aa5908b6b5ca5fefb23_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2058/9c12916c71be4aa5908b6b5ca5fefb23_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2058/9c12916c71be4aa5908b6b5ca5fefb23_s=2000x_.jpg 2000w"} content={null} position={null}>
              </Image>

              <Title className="title-box mb--0 mt--30 pl--0 pr--0" content={"Škoda Superb III"}>
              </Title>

              <Text className="text-box mt--02 pl--0 pr--0" content={"TDI Limuzína"}>
              </Text>

              <Text className="text-box mt--10 pl--0 pr--0" content={"Režim LITE od<span style=\"font-weight: bold;\"> 12 900 Kč </span>/ měsíc"}>
              </Text>

              <Text className="text-box mt--0 pl--0 pr--0" content={"Režim&nbsp;<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;<span style=\"font-weight: bold;\">FULL</span>&nbsp;</span> od <span style=\"font-weight: bold;\">13 900 Kč</span> / měsíc"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 mt--30 pl--0 pr--0" href={"/skoda-superb-iii-tdi-limuzina-cerna"} content={"Zobrazit detail&nbsp; &nbsp;<span style=\"font-weight: normal;\">➔</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--40" style={{"maxWidth":""}}>
              
              <Image className="--left" alt={""} src={"https://cdn.swbpg.com/t/2058/adc21a7ebe91404789ae80ee8e9758aa_s=860x_.jpeg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":400}} srcSet={"https://cdn.swbpg.com/t/2058/adc21a7ebe91404789ae80ee8e9758aa_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2058/adc21a7ebe91404789ae80ee8e9758aa_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2058/adc21a7ebe91404789ae80ee8e9758aa_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/2058/adc21a7ebe91404789ae80ee8e9758aa_s=1400x_.jpeg 1400w"} content={null} position={null}>
              </Image>

              <Title className="title-box mb--0 mt--30 pl--0 pr--0" content={"Škoda Fabia III"}>
              </Title>

              <Text className="text-box mt--02 pl--0 pr--0" content={" STYLE TDI"}>
              </Text>

              <Text className="text-box mt--10 pl--0 pr--0" content={"Režim LITE od<span style=\"font-weight: bold;\"> 5 500 Kč </span>/ měsíc"}>
              </Text>

              <Text className="text-box mt--0 pl--0 pr--0" content={"Režim&nbsp;<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;<span style=\"font-weight: bold;\">FULL</span>&nbsp;</span> od <span style=\"font-weight: bold;\">6 000 Kč</span> / měsíc"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 mt--30 pl--0 pr--0" href={"/skoda-fabia-iii-style-tdi"} content={"Zobrazit detail&nbsp; &nbsp;<span style=\"font-weight: normal;\">➔</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--40" style={{"maxWidth":""}}>
              
              <Image className="--left" alt={""} src={"https://cdn.swbpg.com/t/2058/95bb1b71157a4d178362962e3c2bfb13_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":400}} srcSet={"https://cdn.swbpg.com/t/2058/95bb1b71157a4d178362962e3c2bfb13_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2058/95bb1b71157a4d178362962e3c2bfb13_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2058/95bb1b71157a4d178362962e3c2bfb13_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2058/95bb1b71157a4d178362962e3c2bfb13_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <Title className="title-box mb--0 mt--30 pl--0 pr--0" content={"Ford Focus 1.6"}>
              </Title>

              <Text className="text-box mt--02 pl--0 pr--0" content={" –"}>
              </Text>

              <Text className="text-box mt--10 pl--0 pr--0" content={"Režim LITE od<span style=\"font-weight: bold;\"> 5 800 Kč </span>/ měsíc"}>
              </Text>

              <Text className="text-box mt--0 pl--0 pr--0" content={"Režim&nbsp;<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;<span style=\"font-weight: bold;\">FULL</span>&nbsp;</span> od <span style=\"font-weight: bold;\">6 500 Kč</span> / měsíc"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 mt--30 pl--0 pr--0" href={"/ford-focus"} content={"Zobrazit detail&nbsp; &nbsp;<span style=\"font-weight: normal;\">➔</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--40" style={{"maxWidth":""}}>
              
              <Image className="--left" alt={""} src={"https://cdn.swbpg.com/t/2058/8b1822eacfcf4ddfa8527299c692b0be_s=860x_.jpeg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":400}} srcSet={"https://cdn.swbpg.com/t/2058/8b1822eacfcf4ddfa8527299c692b0be_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2058/8b1822eacfcf4ddfa8527299c692b0be_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2058/8b1822eacfcf4ddfa8527299c692b0be_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/2058/8b1822eacfcf4ddfa8527299c692b0be_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/2058/8b1822eacfcf4ddfa8527299c692b0be_s=2000x_.jpeg 2000w"} content={null} position={{"x":"-6.5%","y":"-100%"}}>
              </Image>

              <Title className="title-box mb--0 mt--30 pl--0 pr--0" content={"Škoda Fabia II stačí 15 000Kč"}>
              </Title>

              <Text className="text-box mt--02 pl--0 pr--0" content={" 1.6 TDI Combi"}>
              </Text>

              <Text className="text-box mt--10 pl--0 pr--0" content={"Režim LITE od<span style=\"font-weight: bold;\">&nbsp;5 800 Kč </span>/ měsíc"}>
              </Text>

              <Text className="text-box mt--0 pl--0 pr--0" content={"Režim&nbsp;<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;<span style=\"font-weight: bold;\">FULL</span>&nbsp;</span> od <span style=\"font-weight: bold;\">6 500 Kč</span> / měsíc"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 mt--30 pl--0 pr--0" href={"/skoda-fabia"} content={"Zobrazit detail&nbsp; &nbsp;<span style=\"font-weight: normal;\">➔</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--40" style={{"maxWidth":""}}>
              
              <Image className="--left" alt={""} src={"https://cdn.swbpg.com/t/11212/a81963b011a04c96822f066cb54622e4_s=350x_.png"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":400}} srcSet={"https://cdn.swbpg.com/t/11212/a81963b011a04c96822f066cb54622e4_s=350x_.png 350w"} content={null} position={null}>
              </Image>

              <Title className="title-box mb--0 mt--30 pl--0 pr--0" content={"Škoda Superb III"}>
              </Title>

              <Text className="text-box mt--02 pl--0 pr--0" content={" TDI Limuzína"}>
              </Text>

              <Text className="text-box mt--10 pl--0 pr--0" content={"Režim LITE od<span style=\"font-weight: bold;\"> 12 900 Kč </span>/ měsíc"}>
              </Text>

              <Text className="text-box mt--0 pl--0 pr--0" content={"Režim&nbsp;<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;<span style=\"font-weight: bold;\">FULL</span>&nbsp;</span> od <span style=\"font-weight: bold;\">13 900 Kč</span> / měsíc"}>
              </Text>

              <Button className="btn-box btn-box--cbtn2 btn-box--cColor2 mt--30 pl--0 pr--0" href={""} target={null} content={"PRODÁNO<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--40" style={{"maxWidth":""}}>
              
              <Image className="--left" alt={""} src={"https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=860x_.jpeg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":400}} srcSet={"https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/2058/9601e1cc141d4c6297be2b71d06334e0_s=2000x_.jpeg 2000w"} content={null} position={null}>
              </Image>

              <Title className="title-box mb--0 mt--30 pl--0 pr--0" content={"Škoda Superb II"}>
              </Title>

              <Text className="text-box mt--02 pl--0 pr--0" content={" 1.6 TDI Limuzína"}>
              </Text>

              <Text className="text-box mt--10 pl--0 pr--0" content={"Režim LITE od<span style=\"font-weight: bold;\"> 7 000 Kč </span>/ měsíc"}>
              </Text>

              <Text className="text-box mt--0 pl--0 pr--0" content={"Režim&nbsp;<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;<span style=\"font-weight: bold;\">FULL</span>&nbsp;</span> od <span style=\"font-weight: bold;\">8 000 Kč</span> / měsíc"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 mt--30 pl--0 pr--0" href={"/skoda-superb-ii-16tdi-limuzina"} content={"Zobrazit detail&nbsp; &nbsp;<span style=\"font-weight: normal;\">➔</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left --parallax pb--60 pt--60" name={"o-nas"} style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l1"} parallax={true} css={css`
          @media (min-width: 1401px) {
            background-image: url(https://cdn.swbpg.com/t/11212/9f40fe1edffc44398e9c6102283a8b4e_ove=000000x65_s=2000x_.jpeg);
          }
          @media (max-width: 1400px) {
            background-image: url(https://cdn.swbpg.com/t/11212/9f40fe1edffc44398e9c6102283a8b4e_ove=000000x65_s=1400x_.jpeg);
          }
        `}>
          
          <ColumnWrap className="column__flex --left el--2 pb--20 pt--20 flex--center" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--left pb--20 pt--10" style={{"maxWidth":613}} border={null}>
              
              <Text className="text-box fs--26 w--500 text-box--invert" content={"<span style=\"color: rgb(251, 201, 0);\">Naši vizí je</span>"}>
              </Text>

              <Title className="title-box fs--62 title-box--invert mt--10" content={"Pomoci lidem s koupí ojetétho auta<br>"}>
              </Title>

              <Text className="text-box text-box--invert" style={{"maxWidth":770}} content={"A to férovou cestou, bez kliček a háčků. Nelíbí se nám praxe autobazarů, nekvalitních aut prodaných s extrémně drahým úvěrem. Věříme, že to jde i jinak!\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}} border={null}>
              
              <Title className="title-box fs--26 lh--14" content={"Výběr aut<br>"}>
              </Title>

              <Text className="text-box fs--16 mt--10" content={"Specializujeme se na auta značky ŠKODA, jezdit můžete v řádu dní. Dodat umíme i jiné značky a typy podle poptávky.\n"}>
              </Text>

              <Title className="title-box fs--26 lh--14" content={"Jedete za pár minut"}>
              </Title>

              <Text className="text-box fs--16 mt--10" content={"Auta máme skutečně skladem. Smlouvu zpracujeme do 30-ti minut a ihned s autem odjíždíte!\n\n\n"}>
              </Text>

              <Title className="title-box fs--26 lh--14" content={"Svoboda volby"}>
              </Title>

              <Text className="text-box fs--16 mt--10" content={"Auta u nás nabízíme ve dvou režimech — v režimu FULL hradíme veškeré náklady na opravy, servis, pneumatiky apod. Nestaráte se vůbec o nic.\n\nV režimu LITE hradíme běžné servisní prohlídky auta. Poruchy, díly mimo běžný servis a pneumatiky si hradíte sami. \n\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--60 pt--60" name={"jak-to-funguje"} style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l1"} parallax={true}>
          
          <ColumnWrap className="column__flex --left el--3 mb--10 mt--10" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--62" content={"Jak to funguje?<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":350}}>
              
              <Title className="title-box fs--26 pl--0 pr--0" content={"První platba<br>"}>
              </Title>

              <Text className="text-box fs--16 mt--16 pb--0 pl--0 pr--0 pt--0" content={"Při odběru auta složíte první platbu, zpravidla 20 000Kč a více, poté už hradíte pravidelné měsíční platby na základě řádných faktur. \n"}>
              </Text>

              <Title className="title-box fs--26 mt--40 pl--0" content={"Splátky a doba placení<br>"}>
              </Title>

              <Text className="text-box fs--16 mt--16 pb--0 pl--0 pr--0 pt--0" content={"Výši splátky a dobu splácení si určíte dle Vašich možností. Platí pravidlo, že maximální doba financování je 4 roky. Čím vyšší jste schopni dát splátku, tím lepší podmínky dostanete. Férově nastavené podmínky.\n \n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":350}}>
              
              <Title className="title-box fs--26 mt--0 pl--0" content={"Servis auta<br>"}>
              </Title>

              <Text className="text-box fs--16 mt--16 pb--0 pl--0 pr--0 pt--0" content={"S autem nebudete mít žádné starosti, pouze jezdíte a my se staráme o zbytek. Neřešíte pneumatiky, servis ani poruchy a nehody. Všechno vyřešíme za Vás. Jezděte bez starostí. Pouze u verze LITE se můžete setkat s nutností platby opravy, poruchy (mimo běžný servis).\n \n"}>
              </Text>

              <Title className="title-box fs--26 mt--40 pl--0" content={"Pojištění<br>"}>
              </Title>

              <Text className="text-box fs--16 mt--16 pb--0 pl--0 pr--0 pt--0" content={"Po celou dobu hradíme kompletní pojištění, nemusíte se tedy bát nečekaných výdajů způsobených nehodou, auto je pojištěno.\n \n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--60 pt--60" name={"tw4xide7esh"} style={{"backgroundColor":"rgba(251,201,0,1)"}} layout={"l1"} parallax={true}>
          
          <ColumnWrap className="column__flex --left el--2 mb--10 mt--10" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0">
              
              <Title className="title-box fs--62 pl--0" style={{"maxWidth":534}} content={"Všechno bereme na sebe!<br>"}>
              </Title>

              <Text className="text-box text-box--left fs--22" content={"<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;✔&nbsp;</span>&nbsp; &nbsp;Neplatíte&nbsp;<span style=\"font-weight: bold;\">servis, pneumatiky, opravy!</span>"}>
              </Text>

              <Text className="text-box text-box--left fs--22" content={"<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;✔&nbsp;</span>&nbsp; &nbsp;Bez nahlížení&nbsp;<span style=\"font-weight: bold;\">do registru!</span>"}>
              </Text>

              <Text className="text-box text-box--left fs--22" content={"<span style=\"background-color: rgb(251, 201, 0);\">&nbsp;✔&nbsp;</span>&nbsp; &nbsp;Náhradní vůz&nbsp;<span style=\"font-weight: bold;\">zdarma!</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":517}}>
              
              <Text className="text-box fs--18 lh--2 mt--06 pb--0 pl--0 pr--0 pt--0" content={"Ve režimu FULL všechno bereme na sebe! <span style=\"font-weight: bold;\">Našim autům věříme a tak po dobu financování jde na nás veškerý servis, pneumatiky, opravy</span> - Vy neplatíte nic!&nbsp;V praxi je to tak, že se Vám například zničí převodovka a o vše se postaráme my, na náš účet! Za normálních okolností byste museli koupit ze svého převodovku. To se Vám s námi nestane.<br>"}>
              </Text>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 pl--0" href={"/poptat"} content={"Online poptávka&nbsp;&nbsp;<span style=\"font-weight: normal;\">➔</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--60 pt--60" style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l1"} parallax={true} css={css`
          @media (min-width: 1401px) {
            background-image: url(https://cdn.swbpg.com/t/11212/9f40fe1edffc44398e9c6102283a8b4e_ove=000000x65_s=2000x_.jpeg);
          }
          @media (max-width: 1400px) {
            background-image: url(https://cdn.swbpg.com/t/11212/9f40fe1edffc44398e9c6102283a8b4e_ove=000000x65_s=1400x_.jpeg);
          }
        `}>
          
          <ColumnWrap className="column__flex --center el--3 mb--08 mt--08" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--left pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null}>
              
              <Title className="title-box" content={"Vyberte si z vozidel skladem."}>
              </Title>

              <Text className="text-box mt--10" content={"Dodání ihned."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2" href={"https://autobezproblemu.cz/#vozy"} content={"Prohlížet vozy"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null}>
              
              <Title className="title-box" content={"Nechte si<br>vytvořit nabídku.<br>"}>
              </Title>

              <Text className="text-box mt--10" content={"Financování na míru."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2" href={"/poptat"} content={"Poptat nabídku"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null}>
              
              <Title className="title-box" content={"Máte dotaz?<br>Kontaktujte nás.<br>"}>
              </Title>

              <Text className="text-box mt--10" content={"Financování na míru."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--cColor2" href={"mailto:info@autobezproblemu.cz"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50">
          
          <ColumnWrap className="column__flex --left el--4 pb--10 pt--10" anim={null} animS={null} style={{"maxWidth":1460}} columns={"4"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":410}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/1c894a4b118646a0b810f1f26828fc71.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":210}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":410}}>
              
              <Text className="text-box fs--26 w--600 lh--14" style={{"maxWidth":310}} content={"Přehoďte veškeré starosti s auty na nás!\n<br>"} uppercase={false}>
              </Text>

              <Text className="text-box mt--12" style={{"maxWidth":336}} content={"+420 555 333 961 info@autobezproblemu.cz<br>8:00-16:00 PO-PÁ"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":410}}>
            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":410}}>
              
              <Text className="text-box text-box--right fs--26 w--600 lh--14" style={{"maxWidth":310}} content={"Máte zájem o auto?<br>"} uppercase={false}>
              </Text>

              <Text className="text-box mt--02" style={{"maxWidth":336}} content={"Na všem se domluvíme."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2" href={"/poptat"} content={"Poptat ONLINE&nbsp; &nbsp;<span style=\"font-weight: normal;\">➔</span>&nbsp;"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"rgba(0,0,0,1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 --full pl--10 pr--10" columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="mb--0 mt--0">
              
              <Text className="text-box text-box--left fs--14 w--600 text-box--invert" content={"Auto bez problémů&nbsp;<span style=\"color: rgb(251, 201, 0);\">(C) 2021</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--0 mt--0">
              
              <Text className="text-box text-box--right fs--14 w--600 text-box--invert" content={"<a href=\"https://saywebpage.com/cs/\">Tvorba webových stránek</a>&nbsp; Saywebpage.com"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeProvider>
  )
}